import React from 'react';
import { Link } from "react-router-dom";
import styles from './ProjectsFond.module.css';
import { staticLinks } from '../../../config/routingsLinks';

const ProjectsFond = () => {
  return (
    <div className={styles.wrapper}>
      <Link to={staticLinks.traces}>Продажа папируса.</Link>
    </div>
  );
}

export default ProjectsFond