import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import styles from './Traces.module.css'
import { staticLinks } from '../../config/routingsLinks';

const Traces = () => {
  const navigate = useNavigate()
  return (
    <div className={styles.wrapper}>
      <div className={styles.navigator}>
        <Link to={staticLinks.fotos}> Мероприятия.</Link>
        <Link to={staticLinks.trecesollbooks}>Ассортимент.</Link>
        <Link>Кабинет автора.</Link>
      </div>
      <div className={styles.list}>
        <div className={styles.wrappe}>
          <textarea rows={15} cols={50} defaultValue="Осуществляем продажу Египетского папируса. Разные форматы. В случае возникновения интереса, напишите. imperiya66.ru@gmail.com"></textarea>
        </div>
      </div>
      <button onClick={() => navigate(-1)} className={styles.button}>
        {"<--"}Назад
      </button>
      <div className={styles.footer}>Подвал. В разрботке.</div>
    </div>
  );
}

export default Traces