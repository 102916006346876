import { staticLinks } from "../config/routingsLinks";
import Fotos from "../pages/Fotos/Fotos";
import { Book } from "../pages/Traces/Book/Book";
import Books from "../pages/Traces/Books/Books";
import ProjectsFond from "../pages/Traces/ProjectsFond/ProjectsFond";
import Traces from "../pages/Traces/Traces";


export const publicRoutes = [
  {
    path: staticLinks.main,
    element: <ProjectsFond></ProjectsFond>,
  },
  {
    path: staticLinks.trecesollbooks,
    element: <Books></Books>,
  },
  {
    path: staticLinks.trecesbooks,
    element: <Book></Book>,
  },
  {
    path: staticLinks.traces,
    element: <Traces></Traces>,
  },
  {
    path: staticLinks.fotos,
    element: <Fotos></Fotos>,
  },
];
