export const staticLinks = {
  main: "/",
  traces: "/fond/:id/ProjectsFond/Traces/",
  trecesbooks: "/treces/books/:id",
  trecesollbooks: "/treces/books/",
  fotos: "/fotos/"
};

export const dynamicLinks = {
  trecesbooks: (id) => `/treces/books/${id}`,
};
